@media only screen and (max-width: 1367px) {
    .graphBoardMain{
        width: 98vw !important;
        margin: 1vw !important;
        height: 88vh !important;
        margin-top: 1vh !important;
    }
}

@media only screen and (min-width: 1920px) {
    .GraphBoard{
        width: 30%;
        height: 28vh;
        margin: 1%;
        border-radius:10px;
        vertical-align: center;
        background-color: rgb(217, 232, 233);
        padding: 10px;
    }
}

@media only screen and (max-width: 1919px) and (min-width:1368px) {
    .GraphBoard{
        width: 46%;
        height: 45vh;
        margin: 1%;
        border-radius:10px;
        vertical-align: center;
        background-color: rgb(217, 232, 233);
        padding: 10px;
    }
}

@media only screen and (max-width:1367px) {
    .GraphBoard{
        width: 86vw;
        height: auto;
        margin: 1%;
        border-radius:10px;
        vertical-align: center;
        background-color: rgb(217, 232, 233);
        padding: 10px;
    }
}

.graphMenuText{
    font-weight: 700 !important;
    border-radius: 30px !important;
    font-size: 10pt !important;
    font-family: 'Times New Roman', Times, serif !important;
    border: rgb(14, 69, 105) 2px solid !important;
}

@media only screen and (min-width: 1920px) {
    .DailyGraphHolderPanel{
        width: 100%;
        height: 100%;
    }
}

@media only screen and (max-width: 1919px) and (min-width:1368px){
    .DailyGraphHolderPanel{
        width: 99%;
        height: 100%;
    }
}

@media only screen and (max-width: 1367px){
    .DailyGraphHolderPanel{
        width: 95vw;
        height: 70vh;
        margin-top: 7vh;
        margin-left:0px !important;
        margin-right: 0px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

@media only screen and (max-width: 1367px) {
    .DailyGraphHolder{
        width: 96vw;
        height: 86vh !important;
        background-color: rgb(14, 69, 105);
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: wrap !important;
        overflow-y: scroll;
        border-radius: 10px;
    }
}

@media only screen and (min-width: 1920px) {
    .DailyGraphHolder{
        width: 100%;
        height: 88vh !important;
        background-color: rgb(14, 69, 105);
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: wrap !important;
        overflow-y: scroll;
        border-radius: 10px;
    }
}

@media only screen and (max-width: 1919px) and (min-width:1368px) {
    .DailyGraphHolder{
        width: 100%;
        height: 83vh !important;
        background-color: rgb(14, 69, 105);
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: wrap !important;
        overflow-y: scroll;
        border-radius: 10px;
    }
}

/* width */
/* width */
@media only screen and (min-width: 1920px) {
    ::-webkit-scrollbar {
      width: 5px;
    }
  
    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey; 
      border-radius: 10px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: red; 
      border-radius: 10px;
    }
  
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #b30000; 
    }
  
    .MuiTreeItem-root{
      background-color: white;
      color: rgb(14, 69, 105) !important;
      font-weight: 700 !important;
      text-decoration: none;
      font-family: 'Times New Roman', Times, serif !important;
    }
  }
  
  @media only screen and (max-width: 1919px) and (min-width:1368px) {
    ::-webkit-scrollbar {
      width: 5px;
    }
  
    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey; 
      border-radius: 10px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: red; 
      border-radius: 10px;
    }
  
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #b30000; 
    }
  
    .MuiTreeItem-root{
      background-color: white;
      color: rgb(14, 69, 105) !important;
      font-weight: 700 !important;
      text-decoration: none;
      font-family: 'Times New Roman', Times, serif !important;
    }
  }
  
  @media only screen and (max-width:1367px) {
    ::-webkit-scrollbar {
      width: 5px !important;
    }
  
    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey; 
      border-radius: 5px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: red; 
      border-radius: 5px;
    }
  
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #b30000; 
    }
  
    .MuiTreeItem-root{
      background-color: white;
      color: rgb(14, 69, 105) !important;
      font-weight: 700 !important;
      text-decoration: none;
      font-family: 'Times New Roman', Times, serif !important;
    }
  }

  .graphMenuText{
    font-weight: 900 !important;
    border-radius: 30px !important;
    font-size: 10pt !important;
    font-family: 'Times New Roman', Times, serif !important;
    margin-right: 1vw !important;
    margin-left: 1vw !important;
    color: rgb(14, 69, 105) !important;
    margin-bottom: 5px !important;
  }

@media only screen and (max-width: 1919px) and (min-width:1368px) {
.PieChartHolder{
    width:55%;
    margin-top:2%;
    margin-bottom:2%;
    }
}

@media only screen and (min-width: 1920px){
.PieChartHolder{
    width:55%;
    margin-top:2%;
    margin-bottom:2%;
    }
}

@media only screen and (max-width: 1367px){
    .PieChartHolder{
        width:80vw;
        margin-top:2%;
        margin-bottom:2%;
        }
    }