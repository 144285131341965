.Booking{
    width: 96%;
    height: 96%;
    margin: 1%;
    border-radius: 10px;
}

.basicInfoFormContainer{
    width: 100%;
    height: 100%;
    height: auto;
    display: flex;
    align-items: center;
    margin-top: 0;
    padding-top: 0;
}

@media only screen and (min-width:1920px){
    .basicInfoFormHolder{
        width: 60vw;
        height: 70vh;
        margin: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 20px;
        justify-content:space-around;
        margin-top: 5vh;
        border-radius: 10px;
        overflow-y: scroll;
    }
}


@media only screen and (max-width:1919px) and (min-width: 1368px){
    .basicInfoFormHolder{
        width: 90%;
        height: 70vh;
        margin: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 20px;
        justify-content: space-around;
        border-radius: 10px;
        overflow-y: scroll;
    }
}

@media only screen and (min-width:1920px){
    .BookingTextFieldHolder{
        width: 350px !important;
        margin: auto;
    }
}

@media only screen and (max-width:1919px) and (min-width: 1368px){
    .BookingTextFieldHolder{
        width: 450px !important;
        margin: auto;
    }
}

.displayNone{
    display: none;
}

.displayBlock{
    display: block;
}

.colorGreen{
    color:green;
}