/* width */
@media only screen and (min-width: 1920px) {
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: red; 
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b30000; 
  }

  .MuiTreeItem-root{
    background-color: white;
    color: lightblue !important;
    font-weight: 700 !important;
    text-decoration: none;
    font-family: 'Times New Roman', Times, serif !important;
  }
}

@media only screen and (max-width: 1919px) and (min-width:1368px) {
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: red; 
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b30000; 
  }

  .MuiTreeItem-root{
    background-color: white;
    color: rgb(14, 69, 105) !important;
    font-weight: 700 !important;
    text-decoration: none;
    font-family: 'Times New Roman', Times, serif !important;
  }
}

@media only screen and (max-width:1367px) {
  ::-webkit-scrollbar {
    width: 5px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(255, 0, 0); 
    border-radius: 5px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: red; 
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b30000; 
  }

  .MuiTreeItem-root{
    background-color: white;
    color: red !important;
    font-weight: 700 !important;
    text-decoration: none;
    font-family: 'Times New Roman', Times, serif !important;
  }
}

  // .Mui-expanded{
  //   background-color: #54777D;
  //   color: white !important;
  //   font-weight: bold !important;
  // }

  // .Mui-expanded:hover{
  //   background-color:black;
  //   color: white !important;
  //   font-weight: bold !important;
  // }

.Mui-selected{
  background-color: #dddddd !important;
  color: rgb(202, 58, 118) !important;
  font-weight: 900;
}

.Mui-selected:hover{
  color: black !important;
  background-color: white !important;
}

.MuiTreeItem-group{
  background-color: black !important;
  color: rgb(14, 69, 105);
}

.MuiTreeItem-group:hover{
  background-color: black !important;
  color: white  !important;
}

.Mui-focused{
  background-color: white;
  color: black;
}

.Mui-focused:hover{
  background-color: white;
  color: rgb(14, 69, 105);
}

.MuiTreeItem-content{
  color: #151d1f !important;
  margin-top: 1px;
  margin-bottom: 1px;
  text-decoration: none;
  font-family: 'Times New Roman', Times, serif !important;
}

.MuiTreeItem-content:hover{
  color: #ca4176 !important;
  background-color: rgb(146, 146, 146);
  margin-top: 1px;
  margin-bottom: 1px;
}

.displayNone{
  display: none;
}

.displayBlock{
  display: block;
}

@media only screen and (min-width: 1920px) {
  .Home{
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
  }
}

@media only screen and (max-width: 1919px) and (min-width: 1368px){
  .Home{
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100vh;
  }
}

@media only screen and (max-width: 1367px){
  .Home{
    display: flex;
    flex-direction: row;
    width: 100vw;
    min-height: 100vh;
    height: auto;
  }
}

@media only screen and (max-width: 1367px) {
  .LogoutMenuDropDown{
  }
}

@media only screen and (min-width: 1920px) {
  .LogoutMenuDropDown{
    width: 4vh;
    position: fixed;
    right: 2vh;
    top: 2vh;
    z-index: 10;
  }
}

@media only screen and (max-width: 1919px) and (min-width: 1368px){
  .LogoutMenuDropDown{
    width: 1vw;
    position: fixed;
    right: 10vh;
    top: 3vh;
    z-index: 10;
  }
}

@media only screen and (max-width: 1367px){
  .hiddeDisplayIfLessThandThirteenHundredSixtyEightPixel{
    display: none;
  }
}


@media only screen and (max-width: 1367px) {
  .MenuIcon{
  }
}

@media only screen and (max-width: 1367px) {
  .NotificationIcon{
  }
}

@media only screen and (min-width: 1920px) {
  .NotificationIcon{
    width: 1vh;
    position: fixed;
    right: 10vh;
    top: 2vh;
    z-index: 10;
  }
}

@media only screen and (max-width: 1919px) and (min-width: 1368px){
  .NotificationIcon{
    width: 1vh;
    position: fixed;
    right: 19vh;
    top: 3vh;
    z-index: 10;
  }
}

.floatDivButton{
  position:fixed;
  width:60px;
  height:60px;
  bottom:10px;
  left:270px;
  background-color:#0C9;
  color:#FFF;
  border-radius:50px;
  text-align:center;
  box-shadow: 2px 2px 3px #999;
}

@media only screen and (max-width: 1367px) {
  .Main{
    width: 97vw;
    height: auto;
    margin-top: 1vh;
    margin-bottom: 1vh;
    border: rgb(14, 69, 105) 2px solid;
    border-radius: 5px;
    margin-right: 1vw;
    margin-left: 1vw;
    min-height: 96vh;
  }
}

@media only screen and (min-width: 1368px) and (max-width:1919px) {
  .Main{
    width: 80vw;
    height: 98vh;
    margin-top: 1vh;
    margin-bottom: 1vh;
    border: rgb(14, 69, 105) 2px solid;
    border-radius: 10px;
    margin-right: 1vw;
    margin-left: 1vw;
  }
}

@media only screen and (min-width: 1920px){
  .Main{
    width: 80vw;
    height: 98vh;
    margin-top: 1vh;
    margin-bottom: 1vh;
    border: rgb(14, 69, 105) 2px solid;
    border-radius: 10px;
    margin-right: 1vh;
    margin-left: 1vh;
  }
}

@media only screen and (max-width: 1367px) {
  .sideBar{
    height: 98vh !important;
    flex-grow: 1 !important;
    width: 96vw !important;
    overflow-Y: auto  !important;
    overflow-X: hidden  !important;
    background-color: white  !important;
    border: rgb(14, 69, 105) 2px solid !important;
    padding-right:2vh !important;
    padding-right: 4 !important;
    border-radius: 10px !important;
    margin-bottom: 1vh !important;
    margin-top: 1vh !important;
    display: none !important;
  }
}

@media only screen and (min-width: 1368px) {
  .sideBar{
    height: 98vh !important;
    flex-grow: 1 !important; 
    width: 13vw !important;
    overflow-Y: auto !important; 
    overflow-X: hidden !important;
    background-color: white !important;
    border: rgb(14, 69, 105) 2px solid !important;
    padding-right: 2vh !important;
    border-radius: 10px !important;
    margin-bottom: 1vh !important;
    margin-top: 1vh !important;
    margin-left: 1vh !important;
  }
}

@media only screen and (max-width: 1367px) {
  .MobileSideBar{
    height: 86vh !important;
    flex-grow: 1 !important;
    width: 90vw !important;
    overflow-Y: auto  !important;
    overflow-X: hidden  !important;
    background-color: white  !important;
    border: rgb(14, 69, 105) 2px solid !important;
    border-radius: 10px !important;
    margin-bottom: 1vh !important;
    margin-top: 12vh !important;
    margin-right: 4vw !important;
    margin-left: 4vw !important;
    padding-right: 1vw !important;
    padding-left: 1vw;
  }
}

@media only screen and (max-width: 1367px) {
  .topBarSideMenu{
    width: 35px !important;
    height: 35px !important;
    background-color:rgb(147, 30, 194) !important;
    padding:2px !important;
    margin: 3px !important;
  }
}

@media only screen and (min-width: 1368px) {
  .topBarSideMenu{
    width: 35px !important;
    height: 35px !important;
    background-color:rgb(219, 15, 110) !important;
    padding:5px !important;
  }
}

@media only screen and (max-width: 1368px) {
  .topBarMenu{
    width: 35px !important;
    height: 35px !important;
    background-color:rgb(228, 33, 124) !important;
    padding:2px !important;
    margin: 3px !important;
  }
}

@media only screen and (min-width: 1368px) {
  .topBarMenu{
    width: 35px !important;
    height: 35px !important;
    background-color:rgb(219, 15, 110) !important;
    padding:5px !important;
  }
}

@media only screen and (max-width: 1367px) {
  .topBar{
    width: 90%;
    height: 8vh;
    background-color: white;
    color: white;
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    border: rgb(14, 69, 105) 2px solid;
    align-items: center;
    vertical-align: auto;
    justify-content: space-between;
    position: fixed;
    right: 4vw;
    z-index: 20;
    top: 2vh;
  }
}

@media only screen and (min-width: 1368px) {
  .NotificationIconBackgroungColor{
    background-color: #a83246 !important;
    padding: 3px;
  }
}

@media only screen and (max-width: 1367px) {
  .NotificationIconBackgroungColor{
    background-color: #a83246 !important;
    padding: 0px;
  }
}

@media only screen and (min-width: 1368px) {
  .topBar{
    display: none;
  }
}

@media only screen and (max-width: 1368px) {
  .LogoutDropDownHolder{
    width: 100px;
    margin-right: 2vw;
  }
}

@media only screen and (min-width: 1368px) {
  .LogoutDropDownHolder{
    width: 100px;
    margin-right: 3vw;
  }
}

.textDecoration{
  text-decoration: none;
}

.BrandNameParagraph{
    text-align:center;
    letter-spacing:5px;
    font-size:4vh;
    margin-top:15px;
    margin-bottom:15px;
    background-color:rgb(14, 69, 105);
    margin-left:20px;
    padding:10px;
    border-radius:10px;
    font-weight:900;
    font-family: 'Times New Roman', Times, serif;
}

@media only screen and (min-width: 1920px) {
  .userNameParagraph{
      text-align:center;
      font-size:  13pt !important;
      padding-left:10px;
      padding-right:10px;
      color:rgb(14, 69, 105);
      font-weight: 600;
  }
}

@media only screen and (max-width: 1919px){
  .userNameParagraph{
      text-align:center;
      font-size:  11pt !important;
      padding-left:10px;
      padding-right:10px;
      color:rgb(14, 69, 105);
      font-weight: 600;
  }
}

@media only screen and (min-width: 1368px) {
  .HomeContainersHolder{
    display: flex;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 1367px) {
  .HomeContainersHolder{
    display: flex;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 10px;
    margin-top: 9vh;
  }
}

@media only screen and (min-width: 1368px) {
  .HomeContainer{
    background-color:rgb(217, 232, 233);
    width: 32%;
    height: 98%;
    border-radius: 10px;
    overflow-x: auto;
  }
}

@media only screen and (max-width: 1367px) {
  .HomeContainer{
    background-color:rgb(217, 232, 233);
    width: 98vw;
    height: 98%;
    border-radius: 10px;
    overflow-x: auto;
    margin-bottom: 3vh;
    margin-right: 1vw;
    margin-left: 1vw;
  }
}

@media only screen and (max-width: 1367px) {
  .HomeContainerFirst{
    background-color:rgb(217, 232, 233);
    width: 98vw;
    height: 98%;
    border-radius: 10px;
    overflow-x: auto;
    margin-bottom: 3vh;
    padding-top: 1vh !important;
    margin-top: 1vh;
  }
}



@media only screen and (max-width: 1367px) {
  .HomeContainerHeadline{
    text-align: center;
    padding: 10px;
    background-color:rgb(14, 69, 105);
    margin:10px;
    border-radius: 10px;
    font-weight: 700;
    font-size: 12pt;
    color: whitesmoke;
  }
}

@media only screen and (min-width: 1368px) {
  .HomeContainerHeadline{
    text-align: center;
    padding: 10px;
    background-color:rgb(14, 69, 105);
    margin:10px;
    border-radius: 10px;
    font-weight: 700;
    font-size: 1vw;
    color: whitesmoke;
  }
}

@media only screen and (min-width: 1368px) {
  .graphMenuText{
    font-weight: 800 !important;
    border-radius: 30px !important;
    font-size: 8pt !important;
    font-family: 'Times New Roman', Times, serif !important;
    background-color: rgb(255, 255, 255) !important;
    margin-right: 1vw !important;
    margin-left: 1vw !important;
    color: rgb(255, 255, 255) !important;
    margin-bottom: 5px !important;
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
}

@media only screen and (max-width: 1367px) {
  .graphMenuText{
    font-weight: 800 !important;
    border-radius: 30px !important;
    font-size: 10px !important;
    font-family: 'Times New Roman', Times, serif !important;
    background-color: rgb(255, 255, 255) !important;
    margin-right: 1px !important;
    margin-left: 1px !important;
    color: rgb(207, 26, 26) !important;
    margin-bottom: 5px !important;
    width: 95px !important;
    max-width: 29vw !important;
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
}

.SalesPersonCardHolderOne{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: -2%;
}

.SalesPersonCardHolderTwo{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: -7%;
}

.SalesPersonCardHolderThree{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: -7%;
}

.SalesPersonCard{
  padding:10px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  margin-top: 10px;
  border-radius: 10px;
  padding-bottom: 0px;
  max-width: 160px;
  border: rgb(14, 69, 105) 1px solid;
  border-bottom-right-radius: 100px;
  border-bottom-left-radius: 100px;
}

.SalesPersonCardText{ 
  text-align:center;
  padding: 0px;
  margin:3px;
  font-family:Georgia, 'Times New Roman', Times, serif;
  font-size: small;
}

@media only screen and (max-width: 1367px) {
  .SalesPersonCardTextHolder{
    background-color: rgb(14, 69, 105);
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 5px;
    color: white;
    width: 240px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 100px;
    border-bottom-left-radius: 100px;
    margin-left: -40px;
    margin-bottom: -2px;
  }
}

@media only screen and (min-width: 1368px) {
  .SalesPersonCardTextHolder{
    background-color: rgb(14, 69, 105);
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 5px;
    color: white;
    width: 200px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 100px;
    border-bottom-left-radius: 100px;
    margin-left: -20px;
    margin-bottom: -2px;
  }
}

@media only screen and (max-width: 1367px) {
  .MobileSideMenuHolder{
    width: 100vw;
    position: fixed;
    min-height: 200vh;
  }
}

@media only screen and (max-width: 1367px) {
  .BestBranchTreeAvater{
    width: 15vw !important;
    height: 15vw !important;
  }
}


