$primary-color: #1d3557;
$error-color: #e63946;
$bg-color: #f1faee;
$text-color: #293241;
$color-white: #fff;
$color-green: #8fffab;

$border-radius: 4px;

.container {
    border-radius: $border-radius;

.success-msg {
        color: $primary-color;
        background: $color-green;
        display: inline-block;
        width: 100%;
        text-align: center;
        padding: 0.5rem;
        border-radius: $border-radius;
        margin-bottom: 1rem;
        font-size: 1.2rem;
        font-weight: 600;
    }

    h1 {
        font-size: 2rem;
        color: $text-color;
        margin-bottom: 2rem;
    }

    label,
        input {
        color: $text-color;
    }

    label {
        font-size: 1.4rem;
        margin-bottom: 0.5rem;
    }

    input {
        border: 2px solid rgba($primary-color, 0.8);
        border-radius: $border-radius;
        padding: 0.5rem 1rem;
        padding-left: 1rem;
        font-size: 1rem;
    }

.form-row {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem;
    }

    button {
        color: $color-white;
        width: 100%;
        font-size: 1.4rem;
        background: $primary-color;
        border-radius: $border-radius;
        border: none;
        cursor: pointer;
        padding: 0.4rem 1.2rem;
        margin-top: 0.5rem;

    &:hover {
            background: rgba($primary-color, 0.8);
        }
    }

.input-error {
        border-color: $error-color;
    }

.error {
        color: $error-color;
        font-size: 10pt;
        margin-top: 0.6rem;
        text-align: center;
    }

.disabled-btn {
        cursor: not-allowed;
        background: rgba($primary-color, 0.8);
    }
  .inputLabel{
    font-size: 10pt;
    text-align: left;
    font-weight: 600;
  }

  .resetPasswordSubmitButton{
    font-size: 12pt;
    padding: 10px;
    margin-top: 0px;
  }

  .resetPasswordSubmitButton:hover{
    color: red;
  }

  .forgotPasswordOtpHolder{
    display: flex;
    flex-direction: row;
    padding-bottom: 30px;
    margin-bottom: 0px;
  }

  .forgotPasswordOtpInputHolder{
    float: left;
  }

  .resetGetOtpButton{
    font-size: 8pt;
    margin-top: 0px;
    padding: 8px;
    width: 80px;
    height: 40px;
  }

  #otp{
    width: 80% !important;
    float: right;
  }

  .otpCounterMessage{
    font-size: 10pt;
    color: green;
    margin-top: 20px;
  }

  .otpInputLabel{
    padding-left: 0px;
    padding-bottom: 5px;
  }
}
