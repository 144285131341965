/* common */
@media only screen and (max-width: 1367px) {
    .loading {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16pt;
        font-family: 'Montserrat', sans-serif;
        font-weight: 800;
        text-align: center;
        color: red;
        span {
            display: inline-block;
        }
        span {
            margin: 0 0.075em;
            animation: loadingShrink 0.7s infinite alternate;
            @for $i from 1 through 7 {
                &:nth-child(#{$i + 1}) {
                    animation-delay: #{$i * 0.1}s;
                }
            }
        }
    }
    @keyframes loadingShrink {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0.8);
        }
    }
}

@media only screen and (min-width: 1368px) {
    .loading {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10vw;
        font-family: 'Montserrat', sans-serif;
        font-weight: 800;
        text-align: center;
        color: red;
        span {
            display: inline-block;
        }
        span {
            margin: 0 0.075em;
            animation: loadingShrink 0.7s infinite alternate;
            @for $i from 1 through 7 {
                &:nth-child(#{$i + 1}) {
                    animation-delay: #{$i * 0.1}s;
                }
            }
        }
    }
    @keyframes loadingShrink {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0.8);
        }
    }
}