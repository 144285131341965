
.card-primary{
    border-top: #e20d0d 3px solid !important;
    width:320px !important;
}

@media only screen and (max-width: 1367px) {
    .custom-card{
        border-radius: 10px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: white;
        width: 80vw !important;
        margin-top: 25vh;
        margin-bottom: 25vh;
    }
}

@media only screen and (min-width: 1368px) {
    .custom-card{
        border-radius: 10px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: white;
    }
}

.custom-card-body{
    padding: 20px;
    padding-top: 0px;
}

.LoginBrandText{
    color: #610329 !important;
    font-weight: 900 !important;
    letter-spacing: 5px;
}

.loginCheckbox{
    background-color: white !important;
    color: black !important;
    display: none !important;
}

.loginForgotPassword{
    background-color: white !important;
    text-align: center;
    margin-top: 20px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
}

.login-box-msg{
    color: #1a6fa1;
    font-weight: 700;
    padding: 5px;
    text-align: center;
}

.custom-login-box{
    width: 100vw !important;
    height: 100vh !important;
    background-color: rgb(12, 13, 37) !important;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-image: url("../image/40235-si-fi-circle.gif");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-top-left-radius: 10000px;
    border-bottom-right-radius: 10000px;
}

.classFor3{
    font-size: 15pt;
    vertical-align:top;
    font-weight: 700;
}

.text-center-custom{
    letter-spacing: 5px;
    color: rgb(11, 61, 107);
    background-color: #2daffa;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
}

.otpButton{
    background-color: white;
    border-radius: 5px;
    font-size: 10pt;
    padding: 9px;
    border: white;
    background-color: #1eb0ba;
    width: 100%;
    color: white;
}

.otpButton:hover{
    background-color: #157a82;
}

.otpMessageDiv{
    text-align: center;
    color: white;
    padding-top: 12px;
    font-size: 10pt;
    font-weight: 500;
    display: block;
}

.otpMessageHide{
    display: none;
}

.notAllowedCursor{
    cursor: not-allowed;
    background-color: white;
    border-radius: 5px;
    font-size: 10pt;
    padding: 9px !important;
    border: red;
}

.ForgotPasswordLoginPageButton{
    color:lavender;
    font-size: 10pt;
    font-weight: 600;
    width: 100%;
    border-radius: 10px;
    border: white 2px solid;
    padding: 5px;
    background-color: #282c34;
}

.ForgotPasswordLoginPageButton:hover{
    color: red;
}

.ForgotPasswordModalHolder{
    display: flex;
    border: 0;
}

@media only screen and (max-width: 1367px) {
    .ForgotPasswordModal{
        width: 80vw;
        height: 100vh;
        margin: auto;
        vertical-align: center;
        display: flex;
        align-items: center;
        border: 0;
    }
}

@media only screen and (min-width: 1367px) {
    .ForgotPasswordModal{
        width: 420px;
        height: 100vh;
        margin: auto;
        vertical-align: center;
        display: flex;
        align-items: center;
        border: 0;
    }
}

@media only screen and (max-width: 1367px) {
    .ForgotPasswordModalMainDiv{
        height: auto;
        min-height: 480px;
        width: 80vw;
        background-color: lavender;
        margin: auto;
        vertical-align: center;
        border: 0;
        border-radius: 10px;
    }
}

@media only screen and (min-width: 1367px) {
    .ForgotPasswordModalMainDiv{
        height: auto;
        min-height: 480px;
        width: 320px;
        background-color: lavender;
        margin: auto;
        vertical-align: center;
        border: 0;
        border-radius: 10px;
    }
}

.displayNone{
    display: none;
}

.displayBlock{
    display: block;
}

.isButtonDisable{
    
}

.cursorDisabled{
    cursor: not-allowed;
}

.forgotPasswordCloseButtonHolder{
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
}

.resetPasswordModalCloseButton{
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    color: red;
    border-radius: 10px;
    border: white;
    font-size: 10pt;
    font-weight: 600;
}

.forgotPasswordFormHolder{
    padding: 15px;
}

.forgotPasswordHedline{
    text-align: center;
    font-weight: 700;
    color: black;
    padding-top:5px;
}

.resetPasswordButton{
    padding-top: 10px;
}

.forgotPasswordOtpMessage{
    color: dodgerblue;
}

.forgotPasswordOtpButton{
    background-color: #1eb0ba;
    border: #6ce3e6 2px solid;
    color: white;
    padding: 8px;
}

.forgotPasswordOtpButton:hover{
    background-color: #157a82;
    border: #6ce3e6 2px solid;
    color: white;
    padding: 8px !important;
    font-size: 10pt;
}

.textCenter{
    text-align: center;
}

.forgotPasswordBrandName{
    padding: 10px;
    background-color: white;
}

.h1{
    font-size: 26pt;
    padding: 10px;
}

.row{
    display: flex;
    flex-direction: row;
}

.textField{
    width: 100%;
}

.textFieldHolder{
    margin-top: 10px;
    margin-bottom: 10px;
}

.loginTextField{
    color: #e20d0d !important;
}

.textFieldOtp{
    width: 90%;
    float: right;
}

.otpButton{
    height: 50px;
}

.otpButtonHolder{
    margin-top: 10px;
    font-size: 10pt;
}

.LoginButtonHolder{
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    align-content: center;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
}

.LoginButton{
    width: 200px;
    background-color: #e20d0d;
    font-weight: 900;
}

#OtpMessage{
    color: #282c34;
}

.ForgotPasswordCancelButtonHolder{
    padding-bottom: 20px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
}

.forgotPasswordHeadline{
    color: darkred;
    font-size: 14pt;
    font-weight: 800;
    text-align: center;
}

