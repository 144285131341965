/* width */
@media only screen and (min-width: 1920px) {
    ::-webkit-scrollbar {
      width: 5px;
    }
  
    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey; 
      border-radius: 10px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: red; 
      border-radius: 10px;
    }
  
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #b30000; 
    }
  
    .MuiTreeItem-root{
      background-color: white;
      color: rgb(14, 69, 105) !important;
      font-weight: 700 !important;
      text-decoration: none;
      font-family: 'Times New Roman', Times, serif !important;
    }
  }
  
  @media only screen and (max-width: 1919px) and (min-width:1368px) {
    ::-webkit-scrollbar {
      width: 5px;
    }
  
    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey; 
      border-radius: 10px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: red; 
      border-radius: 10px;
    }
  
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #b30000; 
    }
  
    .MuiTreeItem-root{
      background-color: white;
      color: rgb(14, 69, 105) !important;
      font-weight: 700 !important;
      text-decoration: none;
      font-family: 'Times New Roman', Times, serif !important;
    }
  }
  
  @media only screen and (max-width:1367px) {
    ::-webkit-scrollbar {
      width: 1px !important;
    }
  
    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey; 
      border-radius: 3px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: red; 
      border-radius: 3px;
    }
  
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #b30000; 
    }
  
    .MuiTreeItem-root{
      background-color: white;
      color: rgb(14, 69, 105) !important;
      font-weight: 700 !important;
      text-decoration: none;
      font-family: 'Times New Roman', Times, serif !important;
    }
  }

@media only screen and (min-width: 1368px) {
    .ProfileHolder{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap-reverse;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
}

@media only screen and (max-width: 1367px) {
    .ProfileHolder{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        height: 98vh;
        overflow-y: scroll;
    }
}

@media only screen and (min-width: 1368px) {
    .ProfileRight{
        width: 28%;
        height: 97%;
        margin: 1%;
        background-color: rgb(217, 232, 233);
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 15px;
    }
}

@media only screen and (max-width: 1367px) {
    .ProfileRight{
        width: 98vw;
        min-height: 98vh;
        height: auto;
        margin: 1%;
        background-color: rgb(217, 232, 233);
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 15px;
        margin-top: 12vh;
        margin-left: 2vw;
        margin-right: 2vw;
    }
}

@media only screen and (max-width: 1367px) {
    .ProfileLeft{
        width: 98vw;
        margin: 2vw;
        min-height: 86vh;
        height: auto;
        background-color: rgb(217, 232, 233);
        border-radius: 5px;
    }
}

@media only screen and (min-width: 1368px) {
    .ProfileLeft{
        width: 68%;
        margin: 1%;
        height: 97%;
        background-color: rgb(217, 232, 233);
        border-radius: 15px;
    }
}

.userNameParagraph{
    text-align:center;
    font-size:  16pt;
    padding-left:10px;
    padding-right:10px;
    color:rgb(14, 69, 105);
    font-weight: 600;
}

.displayNone{
    display: none;
}

.displayBlock{
    display: block;
}

@media only screen and (max-width: 1367px) {
    .profileRightAvatar{
        width: 45vw !important;
        height: 50vw !important;
    }
}
