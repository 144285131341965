@media only screen and (min-width: 1368px) {
    .ManageRolesMainBox{
        display: flex;
        padding: 1vw;
        padding-top: 3vh;
        flex-direction: column;
        margin:auto;
        width: 98%;
    }
}

@media only screen and (max-width: 1367px) {
    .ManageRolesMainBox{
        display: flex;
        padding: 1vw;
        padding-top: 10vh;
        flex-direction: column;
        align-items: center;
        width: 98%;
    }
}

@media only screen and (min-width: 1368px) {
    .ManageRoleTable{
        width: 900px !important;
    }
}

@media only screen and (max-width: 1367px) {
    .ManageRoleTable{
        width: 96vw !important;
    }
}

@media only screen and (min-width: 1368px) {
    .ManagePermissionCardHolder{
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 1367px) {
        .ManagePermissionCardHolder{
            display: flex;
            flex-direction: column;
            width: 100%;
            flex-wrap: wrap;
        }
    }

@media only screen and (min-width: 1368px) {
    .ManagePermissionCard{
        background-color:rgb(14, 69, 105);
        margin:10px;
        padding: 10px;
        border-radius: 10px;
        color: rgb(252, 252, 252);
        display: flex;
        flex-direction: column;
        display: flex;
    }
}

@media only screen and (max-width: 1367px) {
    .ManagePermissionCard{
        background-color: rgb(14, 69, 105);
        margin:10px;
        padding: 10px;
        border-radius: 10px;
        color: rgb(252, 252, 252);
        display: flex;
        flex-direction: column;
        display: flex;
    }
}

.ManagePermissionCardIndex{
    background-color: red;
    max-width: 50px;
    text-align: justify;
    min-height: 50px;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;

}

.ManagePermissionCardButton{
    margin: 5px !important;
}

.ManagePermissionCardTopPortion{
    display: flex;
    align-items:center;
    justify-content:space-between;
}

.ManagePermissionCardMiddleTexts{
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:space-between;
}

.ManagePermissionBottomButtonHolder{
    display: flex;
    align-items:center;
    justify-content:space-between;
}

@media only screen and (min-width: 1920px) {
    .AddPermissionMenu{
        position: fixed;
        right: 1vw;
        bottom: 2vh;
    }
}

@media only screen and (max-width: 1919px) and (min-width:1368px) {
    .AddPermissionMenu{
        position: fixed;
        right: 2vw;
        bottom: 2vh;
    }
}

@media only screen and (max-width: 1367px){
    .AddPermissionMenu{
        position: fixed;
        right: 2vw;
        bottom: 11vh;
    }
}

@media only screen and (min-width: 1920px) {
    .AddRoleMenu{
        position: fixed;
        right: 4vw;
        bottom: 2vh;
    }
}

@media only screen and (max-width: 1919px) and (min-width:1368px) {
    .AddRoleMenu{
        position: fixed;
        right: 6vw;
        bottom: 2vh;
    }
}

@media only screen and (max-width: 1367px){
    .AddRoleMenu{
        position: fixed;
        right: 2vw;
        bottom: 2vh;
    }
}


.addPermissionModalMAinDiv{
    width: 400px;
    background-color: white;
    margin: auto;
    margin-top: auto;
    margin-bottom: auto;
    vertical-align: auto;
    margin-top: 10vh;
    padding:20px;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:space-between;
    border-radius: 10px;
}

.addPermissionModalFormHolder{
    width: 350px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:space-between;
}

.addPermissionModalMAinDivHeadline{
    font-weight: 800 !important;
    background-color: rgb(14, 69, 105) !important;
    padding:20px !important;
    color: white;
    margin-top: 0px;
    width: 350px;
    text-align: center;
    font-size: 16pt;
    border-radius: 10px;
}

.addPermissionSubmitButton{
    margin-top: 20px !important;
    width: 200px;
    font-weight: 900 !important;
    background-color: red !important;
}

@media only screen and (max-width: 1367px){
    .ManageRolesPermissionModalMAinDiv{
        width: 80vw;
        background-color: white;
        margin: auto;
        margin-bottom: auto;
        vertical-align: auto;
        padding:20px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items:center;
        border-radius: 10px;
        padding-top:5vh;
        padding-bottom: 5vh;
        overflow-y: scroll;
        height: 70vh;
    }
}

@media only screen and (min-width: 1367px){
    .ManageRolesPermissionModalMAinDiv{
        width: 60vw;
        background-color: white;
        margin: auto;
        margin-bottom: auto;
        vertical-align: auto;
        margin-top: 0vh;
        padding:20px;
        display: flex;
        flex-direction: row;
        align-items:center;
        justify-content:space-around;
        border-radius: 10px;
        padding-top:3vh;
        padding-bottom: 3vh;
        flex-wrap: wrap;
        overflow-y: scroll;
        height: 70vh;
    }
}

@media only screen and (max-width: 1367px){
    .ManageRolesPermissionModalHeadline{
        font-size: 3vw;
        text-align: center;
        background-color: red;
        width: 86vw;
        margin: auto;
        margin-top: 4vh;
        padding: 3vh;
        color: white;
        font-weight: 900;
        border-radius: 5vw;
    }
}

@media only screen and (min-width: 1368px){
    .ManageRolesPermissionModalHeadline{
        font-size: 2vw;
        text-align: center;
        background-color: red;
        width: 62vw;
        margin: auto;
        margin-top: 4vh;
        padding: 2vh;
        color: white;
        font-weight: 900;
        border-radius: 5vw;
    }
}

.closePermissionModal{
    position: fixed;
    left: 48vw;
    bottom: 3vh;
}

.createInputClass{
    width: 90%;
    height: 40px;
    font-size: 15pt;
    text-align: center;
    border-radius: 5px;
}
